import React from "react";
import { useField, useFormikContext } from "formik";
import Select from "react-select";

const AASelectInput = ({
  label,
  hint,
  withEmptyOption = true,
  boolean = false,
  options = [],
  isFieldValueObject,
  wrapperClassName = '',
  ...props
}) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  const onChange = selection => {
    let value = '';

    if (isFieldValueObject) value = selection;
    else if (Array.isArray(selection)) value = selection.map((s) => s.value);
    else value = (selection === null) ? null : selection.value;
  
    setFieldValue(props.name, value);
  }

  if (boolean) {
    options = [
      { value: true, label: "Да" },
      { value: false, label: "Нет" },
    ];
  }

  // Поиск значения в опциях
  const findValue = (val) => options.find((option) => option.value === val);

  const getValue = () => {
    if (isFieldValueObject) return field.value;

    if (Array.isArray(field.value)) return field.value.map(findValue);

    return findValue(field.value);
  }

  return (
    <li className={wrapperClassName}>
      {label && <label htmlFor={props.id || props.name} className="custom_label">{label}</label>}
      <Select
        {...field}
        {...props}
        options={options}
        value={props.value || getValue()}
        onBlur={field.onBlur}
        onChange={onChange}
      />
      {meta.error ? (
        <p className="custom_error">{meta.error}</p>
      ) : null}
      {hint ? <p className="custom_hint">{hint}</p> : null}
    </li>
  );
};

export default AASelectInput;
