export const diagnosisStatusOptions = [
  { value: "unconfirmed", label: "Не подтверждён" },
  { value: "confirmed", label: "Подтверждён" },
  { value: "ignored", label: "Нет, игнорируем" }
];

export const typeOptions = [
  { value: "prescription", label: "Рецепт" },
  { value: "receipt", label: "Чек" },
  { value: "additional_prescription", label: "Дополнительный рецепт" },
  { value: "receipt_part", label: "Часть чека" },
  { value: "duplicate", label: "Дубликат" },
  { value: "prescription_part", label: "Часть рецепта" },
  { value: "other", label: "Иное" },
];

export const prescriptionTypeOptions = [
  { value: "appointment", label: "Назначение" },
  { value: "appointment_telemed", label: "Назначение (телемедицина)" },
  { value: "prescription", label: "Рецепт" },
  { value: "extract", label: "Выписка из больницы" },
];

export const prescriptionBlankTypeOptions = [
  "Свободная форма",
  "Форма № 107/у-НП",
  "Форма № 148-1/у-88 «Рецептурный бланк»",
  "Форма № 107-1/у «Рецептурный бланк»",
  "Форма № 148-1/04 (л)",
].map((value) => ({ value: value, label: value }));

export const excludedFromProgramOptions = [
  'ВИЧ-инфекции, СПИД',
  'Гемобластозы',
  'Опухолевые заболевания нервной системы',
  'Психические расстройства и расстройства поведения',
  'Сахарный диабет',
  'Туберкулез',
  'Гепатиты',
  'Системные поражения соединительной ткани, в т.ч. все недифференцированные коллагенозы',
  'Ревматоидный артрит',
  'Глубокие и распространенные микозы',
  'Дегенеративные и демиелинизирующие болезни нервной системы',
  'Эпилепсии, эписиндромы',
  'Наследственные заболевания, врожденные аномалии, пороки развития',
  'Детский церебральный паралич',
  'п.4.2.9 бесплодие, импотенция,  а также гормональные препараты, используемые в контрацептивных целях.',
  'п. 4.2.11 назначенных при беременности, для прерывания беременности',
  'п. 4.2.10 применяемые для проведения иммунопрофилактики и иммунотерапии',
  'п. 4.2.8 Заболеваний, сопровождающихся хронической почечной или печеночной недостаточностью и требующих проведения хронического гемодиализа.',
  'п. 4.2.12 Лекарственные препарты назначены по причине проведения профилактики заболеваний',
  '4.2.1. расходы на лекарственные препараты, купленные в дозах, превышающих назначенные врачом и зафиксированные в Медицинском документе, Рецепте(в части превышения доз, назначенных врачом)',
  '4.2.4. расходы на лекарственные препараты, назначенные по поводу заболеваний, состояний, не указанных в Медицинских документах, приложенных к Заявлению',
  '4.2.14. расходы на лекарственные препараты, не прошедшие государственную регистрацию в соответствие с законодательством РФ и не внесенные в государственный реестр лекарственных средств',
  '4.2.15. расходы на лекарственные препараты, отсутствующие в готовых лекарственных формах',
  '4.2.17. расходы на БАД, гомеопатические препараты, питательные смеси и препараты для лечебного питания, лечебную косметику',
  '4.2.18. расходы на изделия медицинского назначения, в т.ч. средств личной гигиены, перевязочных материалов, медицинского оборудования, очков, контактных линз, слуховых аппаратов, имплантатов и др.',
  '4.2.19. расходы на оказание медицинских услуг и иных услуг, не указанных в настоящей Программе страхования',
  'Регистрационный статус П - препарата - не относит его к категории лекарственных средств и компенсации не подлежит',
  '4.2.6. возникновение расходов на лекарственные препараты, применяемые для лечения по поводу расстройств здоровья, вызванных употребления алкоголя, наркотических или токсических веществ, а также полученных Застрахованным в этих состояниях травм, ожогов, отморожений, острых отравлений, повреждений внутренних органов; умышленного причинения себе телесных повреждений, в том числе с покушением на самоубийство.'
].map((value) => ({ value: value, label: value }));

export const purchaseReleaseFormOptions = [
  "Капсулы",
  "Таблетки",
  "Свечи",
  "Гель",
  "Мазь",
  "Сироп",
  "Экстракт",
  "Эликсир",
  "Эмульсия",
  "Карандаш",
  "Отвар",
  "Микстура",
  "Раствор",
  "Порошок",
].map((value) => ({ value: value, label: value }));
